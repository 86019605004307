import React from 'react';
import Router from './Router';
import { Notifications } from '@mantine/notifications';
function App() {
  return (
    <>
      <Notifications position='top-right' />
      <Router />
    </>
  );
}

export default App;
