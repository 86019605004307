import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LandingNav from 'components/LandingNav';
import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Grid,
  Select,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { onRegister, registerState } from 'features/RegisterSlice';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { find, filter, get } from 'lodash';
import {
  formatCountries,
  formatProvinces,
  formatCities,
} from 'components/common/functions';
import countries from 'assets/data/countries';
import provinces from 'assets/data/provinces';
import cities from 'assets/data/cities';

export default function RegisterScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector(registerState);
  const [country, setCountry] = useState('');
  const [province, setProvince] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedCities, setSelectedCities] = useState('');
  const [municipality, setMunicipality] = useState('');
  const form = useForm({
    initialValues: {
      password: '',
      confirm_password: '',
      first_name: '',
      last_name: '',
      operator: '',
      position: '',
      email: '',
      country: '',
      province: '',
      municipality: '',
    },

    validate: {
      confirm_password: (value, values) =>
        value !== values.password ? 'Passwords did not match' : null,
      first_name: (value) =>
        /^([^0-9]*)$/.test(value) ? null : 'Must be composed of letters only',
      last_name: (value) =>
        /^([^0-9]*)$/.test(value) ? null : 'Must be composed of letters only',
      affiliation: (value) =>
        /^([^0-9]*)$/.test(value) ? null : 'Must be composed of letters only',
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  useEffect(() => {
    const selectedProv = find(provinces, (o) => {
      return o.province_name === province;
    });
    setSelectedProvince(selectedProv);
  }, [province]);

  useEffect(() => {
    if (selectedProvince) {
      const getCities = filter(cities, (o) => {
        return o.province_code === selectedProvince.province_code;
      });
      setSelectedCities(getCities);
    }
  }, [selectedProvince]);

  const handleSubmit = (data) => {
    dispatch(onRegister(data)).then((res) => {
      if (get(res, 'payload.info')) {
        showNotification({
          message: get(res, 'payload.info', 'Account created!'),
          color: 'green',
        });
        navigate('/login');
      } else {
        showNotification({
          message: get(res, 'payload.error', 'Something went wrong!'),
          color: 'red',
        });
      }
    });
  };

  return (
    <div>
      <LandingNav />
      <Container size={600} my={40}>
        <Title
          align='center'
          sx={(theme) => ({
            fontFamily: `Poppins`,
            fontWeight: 900,
          })}
        >
          Welcome to Philippine Registry of Space Objects!
        </Title>
        <Text color='dimmed' size='sm' align='center' mt={5}>
          Already have an account?{' '}
          <Link to='/login' size='sm' component='button'>
            Sign in here
          </Link>
        </Text>

        <Paper withBorder shadow='md' p={30} mt={30} radius='md'>
          <form
            onSubmit={form.onSubmit((values) => {
              handleSubmit(values);
            })}
          >
            <TextInput
              type='email'
              label='Email'
              placeholder='you@email.dev'
              required
              {...form.getInputProps('email')}
            />
            <Grid>
              <Grid.Col span={6}>
                <PasswordInput
                  required
                  mt='sm'
                  label='Password'
                  placeholder='Password'
                  {...form.getInputProps('password')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <PasswordInput
                  required
                  mt='sm'
                  label='Confirm password'
                  placeholder='Confirm password'
                  {...form.getInputProps('confirm_password')}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  required
                  mt='sm'
                  label='First Name'
                  placeholder='First name'
                  {...form.getInputProps('first_name')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  required
                  mt='sm'
                  label='Last Name'
                  placeholder='Last name'
                  {...form.getInputProps('last_name')}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  required
                  mt='sm'
                  label='Operator'
                  placeholder='Operator'
                  {...form.getInputProps('operator')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  required
                  mt='sm'
                  label='Position'
                  placeholder='Position'
                  {...form.getInputProps('position')}
                />
              </Grid.Col>
            </Grid>

            <Select
              required
              mt='sm'
              label='Country'
              searchable
              clearable
              onChange={(e) => {
                setCountry(e);
                form.setFieldValue('municipality', '');
                form.setFieldValue('province', '');
                form.setFieldValue('country', e);
                setSelectedCities('');
              }}
              data={formatCountries(countries)}
            />

            {country === 'Philippines' && (
              <Select
                required
                mt='sm'
                label='Province'
                searchable
                clearable
                onChange={(e) => {
                  setProvince(e);
                  form.setFieldValue('municipality', '');

                  form.setFieldValue('province', e);
                }}
                data={formatProvinces(provinces)}
              />
            )}

            {country === 'Philippines' && selectedCities && (
              <Select
                required
                {...form.getInputProps('municipality')}
                mt='sm'
                label='Municipality'
                searchable
                clearable
                onChange={(e) => {
                  setMunicipality(e);
                  form.setFieldValue('municipality', e);
                }}
                data={formatCities(selectedCities)}
              />
            )}

            <Button loading={loading} type='submit' fullWidth mt='xl'>
              Sign Up
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
}
