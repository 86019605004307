export const formatCountries = (data) => {
  return data.map((e) => {
    return { label: e.label, value: e.label };
  });
};

export const formatProvinces = (data) => {
  return data.map((e) => {
    return { label: e.province_name, value: e.province_name, ...e };
  });
};

export const formatCities = (data) => {
  return data.map((e) => {
    return { label: e.city_name, value: e.city_name, ...e };
  });
};

export const colorStatus = (status) =>
  status === 'Active' ? 'bg-lime-600' : 'bg-red-600';
