import API from 'services/API';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const onRegisterObject = createAsyncThunk(
  'object/onRegisterObject',
  async (formData) => {
    try {
      const response = await API.post(
        `${process.env.REACT_APP_API}/space_objects/`,
        formData
      );

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getSpaceObjects = createAsyncThunk(
  'objects/getSpaceObjects',
  async () => {
    try {
      const response = await API.get(
        `${process.env.REACT_APP_API}/space_objects/`
      );

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const viewSpaceObject = createAsyncThunk(
  'objects/viewSpaceObject',
  async ({ id }) => {
    try {
      const response = await API.get(
        `${process.env.REACT_APP_API}/space_objects/${id}`
      );

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const ObjectSlice = createSlice({
  name: 'object',
  initialState: {
    loading: false,
    space_objects: [],
    space_object: {},
  },
  reducers: {},
  extraReducers: {
    [onRegisterObject.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [onRegisterObject.pending]: (state, action) => {
      state.pending = action.error;
      state.loading = true;
    },
    [onRegisterObject.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [getSpaceObjects.fulfilled]: (state, action) => {
      state.space_objects = get(action, 'payload');
      state.loading = false;
    },
    [getSpaceObjects.pending]: (state, action) => {
      state.pending = action.error;
      state.loading = true;
    },
    [getSpaceObjects.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [viewSpaceObject.fulfilled]: (state, action) => {
      state.space_object = get(action, 'payload');
      state.loading = false;
    },
    [viewSpaceObject.pending]: (state, action) => {
      state.pending = action.error;
      state.loading = true;
    },
    [viewSpaceObject.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const objectState = (state) => state.object;

export default ObjectSlice.reducer;
