import { useState } from 'react';
import { createStyles, Navbar, Group, getStylesRef } from '@mantine/core';
import { IconHome2, IconUserCircle, IconLogout } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/white.png';
import { Link } from 'react-router-dom';
import Image from 'components/Image';

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color: theme.white,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.fn.lighten('#00112C', 0.2),
    },
  },

  linkIcon: {
    ref: getStylesRef('icon'),
    color: theme.white,
    opacity: 0.75,
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    '&, &:hover': {
      [`& .${getStylesRef('icon')}`]: {
        opacity: 0.9,
      },
    },
  },
}));

const data = [
  { link: '/home', label: 'Dashboard', icon: IconHome2 },
  { link: '/profile', label: 'Profile', icon: IconUserCircle },
];

export default function SideNav() {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState('Billing');
  const navigate = useNavigate();
  const links = data.map((item) => (
    <a
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.label);
        navigate(item.link);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </a>
  ));

  return (
    <Navbar
      height={'100vh'}
      p='md'
      className='bg-[#00112C] md:fixed w-[300px] md:flex hidden  mr-60'
    >
      <Navbar.Section grow>
        <Group className={classes.header} position='apart'>
          <Link to='/' className='w-28 h-8'>
            <Image className='h-8 w-full' src={logo} />
          </Link>
        </Group>
        {links}
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <a
          href='#'
          className={classes.link}
          onClick={(event) => {
            event.preventDefault();
            localStorage.removeItem('auth');
            window.location.href = '/';
          }}
        >
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>
        </a>
      </Navbar.Section>
    </Navbar>
  );
}
