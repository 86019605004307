import { configureStore } from '@reduxjs/toolkit';
import landing from 'features/LandingSlice';
import register from 'features/RegisterSlice';
import auth from 'features/AuthSlice';
import object from 'features/ObjectSlice';

export default configureStore({
  reducer: {
    landing,
    register,
    auth,
    object,
  },
});
