import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoutes from 'components/ProtectedRoutes';
import LandingScreen from 'pages/LandingScreen';
import LandingViewScreen from 'pages/LandingViewScreen';
import LoginScreen from 'pages/LoginScreen';
import HomeScreen from 'pages/HomeScreen';
import RegisterScreen from 'pages/RegisterScreen';
import AuthScreen from 'pages/AuthScreen';
import RegisterSpaceObjectScreen from 'pages/RegisterSpaceObjectScreen';
import SpaceObjectViewScreen from 'pages/SpaceObjectViewScreen';
import ProfileScreen from 'pages/ProfileScreen';

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/register' element={<RegisterScreen />} />
        <Route path='/login' element={<LoginScreen />} />
        <Route path='/view/:name' element={<LandingViewScreen />} />
        <Route element={<ProtectedRoutes />}>
          <Route element={<AuthScreen />}>
            <Route path='/home' element={<HomeScreen />} />
            <Route path='/profile' element={<ProfileScreen />} />
            <Route
              path='/space_object/:id'
              element={<SpaceObjectViewScreen />}
            />
            <Route
              path='/space_object/register'
              element={<RegisterSpaceObjectScreen />}
            />
          </Route>
        </Route>

        <Route path='/' element={<LandingScreen />} />
      </Routes>
    </BrowserRouter>
  );
}
