import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { colorStatus } from 'components/common/functions';

export default function ObjectItem({ object }) {
  const { operator, date_launch, image, name, additional_information } = object;

  return (
    <div
      key={name}
      className='relative font-ptSans  border-2 border-slate-700 border-solid'
    >
      <Link className='no-underline' to={`/view/${name}`}>
        <div
          className='bg-cover w-full bg-no-repeat h-64 bg-center flex justify-center items-end '
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className='bg-[#1F1F1F] text-white w-full p-2 flex justify-between items-center  min-h-[20%]'>
            <div className='w-5/6'>
              <div className='font-bold text-sm'>{name}</div>
              <div className='font-poppins text-xs line-clamp-1'>
                {operator}
              </div>
              <div className='font-poppins text-xs'>
                Date of Launch: {moment(date_launch).format('DD MMMM YYYY')}
              </div>
            </div>
            <div
              className={` text-white p-2 rounded-lg text-xs font-bold w-6/6 ${colorStatus(
                get(additional_information, '0.operational_status')
              )}`}
            >
              {get(additional_information, '0.operational_status')}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
