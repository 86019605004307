import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, LoadingOverlay, Table } from '@mantine/core';
import { IconArrowNarrowLeft } from '@tabler/icons-react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getPublicSpaceObjects, landingState } from 'features/LandingSlice';
import LandingNav from 'components/LandingNav';
import Footer from 'components/Footer';
import moment from 'moment';

export default function LandingViewScreen() {
  const params = useParams();
  const dispatch = useDispatch();
  const [object, setObject] = useState({});
  const { loading } = useSelector(landingState);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(getPublicSpaceObjects()).then((res) => {
      get(res, 'payload.results', []).map((object) => {
        if (object.name === params.name) {
          setObject(object);
        }
      });
    });
  }, []);
  return (
    <div>
      <LandingNav />
      <LoadingOverlay
        visible={loading}
        overlayBlur={10}
        loaderProps={{ size: 'lg', color: 'darkblue' }}
      />
      <div className='bg-[#00112C] min-h-[40rem]'>
        <div className='max-w-5xl mx-auto'>
          <div className='pt-10'>
            <Button
              onClick={() => window.history.back()}
              leftIcon={<IconArrowNarrowLeft />}
              sx={{ backgroundColor: '#00112C', color: '#fff', paddingLeft: 0 }}
              variant='white'
            >
              Back
            </Button>{' '}
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 md:gap-8 gap-2 text-white py-14 font-ptSans items-center mx-5 md:mx-0'>
            <div className='w-full '>
              <div className=' text-4xl font-bold'>{object.name}</div>
              <div className='mt-4 mb-7'>
                {object.name} Registration Details Overview
              </div>

              <Table>
                <tbody className='text-white p-3 '>
                  <tr>
                    <td>Cospar Designator</td>
                    <td>{get(object, 'cospar_international_designator')}</td>
                  </tr>
                  <tr>
                    <td>Operator</td>
                    <td>{get(object, 'operator')}</td>
                  </tr>
                  <tr>
                    <td>Date of Launch</td>
                    <td>
                      {' '}
                      {moment(get(object, 'date_launch')).format(
                        'DD MMMM YYYY'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Launch Vehicle</td>
                    <td>{get(object, 'launch_vehicle')}</td>
                  </tr>
                  <tr>
                    <td>Other Information:</td>
                    <td>
                      <a
                        className='text-white break-words block'
                        target='_blank'
                        href={get(object, 'website')}
                      >
                        Link to website
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className='font-ptSans text-4xl font-bold m-5'>
              <img
                className='w-full object-contain'
                src={get(object, 'image')}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
