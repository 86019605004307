import React from 'react';
import { Table } from '@mantine/core';

export default function TableSummary({ data }) {
  const {
    name,
    other_launching_state,
    cospar_international_designator,
    date_launch,
    national_designator,
    location_launch,
    nodal_period,
    inclination,
    apogee,
    perigee,
    general_function,
    geostationary_position,
    website,
    operator,
    launch_vehicle,
    celestial_body_orbiting,
    other_information,
  } = data;
  return (
    <Table withColumnBorders withBorder className='w-[100%] col-span-6'>
      <tbody>
        <tr>
          <td colSpan='2' className='font-bold'>
            Launching State/States/international intergovernmental organization
          </td>
        </tr>
        <tr>
          <td>
            State of registry or international intergovernmental organization:
          </td>
          <td>PHILIPPINES</td>
        </tr>
        <tr>
          <td>Other Launching State:</td>
          <td>{other_launching_state}</td>
        </tr>
        <tr>
          <td colSpan='2' className='font-bold '>
            Designator
          </td>
        </tr>
        <tr>
          <td>Space Object Name:</td>
          <td>{name}</td>
        </tr>
        <tr>
          <td>COSPAR International designator:</td>
          <td>{cospar_international_designator}</td>
        </tr>
        <tr>
          <td>
            National designator/registration number as used by State of registry
            :
          </td>
          <td>{national_designator}</td>
        </tr>
        <tr>
          <td colSpan='2' className='font-bold'>
            Date and territory or location of launch
          </td>
        </tr>
        <tr>
          <td> Date of Launch</td>
          <td> {date_launch}</td>
        </tr>
        <tr>
          <td> Territory or location of launch</td>
          <td> {location_launch}</td>
        </tr>
        <tr>
          <td colSpan='2' className='font-bold'>
            Basic orbital parameters
          </td>
        </tr>
        <tr>
          <td> Nodal Period</td>
          <td> {nodal_period}</td>
        </tr>
        <tr>
          <td> Inclination</td>
          <td> {inclination}</td>
        </tr>
        <tr>
          <td> Apogee</td>
          <td> {apogee}</td>
        </tr>
        <tr>
          <td> Perigee</td>
          <td> {perigee}</td>
        </tr>
        <tr>
          <td colSpan='2' className='font-bold'>
            General Function
          </td>
        </tr>
        <tr>
          <td> General Function</td>
          <td> {general_function}</td>
        </tr>
        <tr>
          <td colSpan='2' className='font-bold'>
            Basic Information
          </td>
        </tr>
        <tr>
          <td> Space object owner or operator</td>
          <td> {operator}</td>
        </tr>
        <tr>
          <td> Launch Vehicle</td>
          <td> {launch_vehicle}</td>
        </tr>
        <tr>
          <td> Celestial body space object is Orbiting</td>
          <td> {celestial_body_orbiting}</td>
        </tr>
        <tr>
          <td colSpan='2' className='font-bold'>
            Other Information
          </td>
        </tr>
        <tr>
          <td> Geostationary Position</td>
          <td> {geostationary_position}</td>
        </tr>
        <tr>
          <td> Website</td>
          <td> {website}</td>
        </tr>
        <tr>
          <td> Other Information</td>
          <td>{other_information}</td>
        </tr>
      </tbody>
    </Table>
  );
}
