import React from 'react';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';

export default function Footer() {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem('auth');

  return (
    <>
      <div className='bg-[#1F1F1F] '>
        <div className='max-w-5xl mx-auto text-white py-16 px-4'>
          <div className='font-poppins font-bold text-3xl '> Contact Us</div>
          <div className='grid grid-cols-2 font-ptSans md:gap-6 gap-2 mt-9  '>
            <div>Email: info@philsa.gov.ph</div>
            {/* <div>Quick Links</div> */}
            <div>
              Address: 29th Floor, Cyber One Building, 11 Eastwood Ave.,
              Bagumbayan Quezon City
            </div>
            
            <div>Phone: +632 8568 99 31</div>
            {/* <div>Official List of Philippine Space Objects</div> */}
            <div>
              <a
                className='no-underline text-white'
                href='https://philsa.gov.ph/'
                target='_blank'
              >
                Website: philsa.gov.ph
              </a>
            </div>
            {/* <div>
              <a
                className='no-underline text-white'
                href='https://philsa.gov.ph/about/'
                target='_blank'
              >
                About PhilSA
              </a>
            </div> */}
            {/* <div className='cursor-pointer'>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  if (isLoggedIn) {
                    navigate('/space_object/register');
                  } else {
                    showNotification({
                      message: 'Please login to register space object',
                      color: 'red',
                      autoClose: 5000,
                    });
                  }
                }}
              >
                Register Space Object
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className='bg-black text-white text-center font-poppins py-7'>
        Copyright Philippine Space Agency 2024
      </div>
    </>
  );
}
