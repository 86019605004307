import React, { useEffect } from 'react';
import { Button } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import ObjectItem from 'components/ObjectItem';
import LandingNav from 'components/LandingNav';
import Footer from 'components/Footer';
import { getPublicSpaceObjects, landingState } from 'features/LandingSlice';
import banner from 'assets/banner.png';
import { get, sortBy } from 'lodash';
import logoBig from 'assets/logo-big.png';
import Image from 'components/Image';

export default function LandingScreen() {
  const dispatch = useDispatch();
  const { public_space_objects } = useSelector(landingState);
  useEffect(() => {
    dispatch(getPublicSpaceObjects());
  }, []);

  return (
    <div>
      <LandingNav />
      <div
        className='h-96 w-full bg-cover bg-no-repeat bg-center flex justify-center items-center bg-fixed '
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className='max-w-5xl mx-auto text-white md:text-5xl text-3xl px-5  font-poppins font-bold text-center leading-snug '>
          Philippine Registry of Space Objects
        </div>
      </div>
      <div className='max-w-5xl mx-5 md:mx-auto mb-20'>
        <div className='text-blue-900 text-center md:text-3xl font-bold  md:my-10 my-4 font-ptSans text-xl '>
          Official list of registered space objects in the Philippines
        </div>
        <div className='grid md:grid-cols-3 gap-4 sm:grid-cols-1'>
          {sortBy(get(public_space_objects, 'results', []), (item) => {
            const operationalStatus =
              item.additional_information[0].operational_status;
            const launchDate = item.date_launch;
            if (operationalStatus === 'Active') {
              return 0;
            } else {
              return;
              new Date(launchDate);
            }
          }).map((object, i) => (
            <ObjectItem key={i} object={object} />
          ))}
        </div>
      </div>
      <div className='max-w-5xl  grid md:mx-auto mx-5 my-10 grid-cols-1 '>
        <div>
          <div className='text-blue-900 font-poppins font-bold text-2xl text-center'>
            About the Philippine Registry of Space Objects
          </div>
          <div className='font-ptSans text-base mt-5 text-justify max-w-[50rem] mx-auto'>
            The Philippine Registry of Space Objects is an online archive
            containing a list of all the space objects launched and owned by the
            Philippines. Directed by the Philippine Space Act (Republic Act No. 11363), the
            Philippine Space Agency (PhilSA) shall maintain a national registry
            of space objects in accordance with the United Nations Convention on
            Registration of Objects Launched into Outer Space and other
            international guidelines. The PhilSA should also furnish the United
            Nations Office for Outer Space Affairs (UNOOSA) with information
            contained in the National Registry of Space Objects, as required
            under the Registration Convention.
          </div>
        </div>
      </div>

      <div
        className='h-auto w-full bg-cover bg-no-repeat bg-center mt-20 bg-fixed  '
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className='max-w-5xl mx-auto text-white p-24 font-poppins leading-snug grid md:grid-cols-2 grid-cols-1 items-center '>
          <div className='md:w-96 w-full flex items-center justify-center my-8'>
            <Image src={logoBig} />
          </div>
          <div className='font-ptSans text-base flex flex-col justify-end text-center md:text-right'>
            <div className='font-poppins text-3xl font-bold mb-10 text-left'>
              About the Philippine Space Agency
            </div>
            <div className='text-justify'>
              The Philippine Space Agency (PhilSA) is the central government agency addressing all national
              issues and activities related to space science and technology applications. Created
              under the Republic Act No. 11363 or the Philippine Space Act, the PhilSA
              is an attached agency of the Office of the President for purposes
              of policy and program coordination, and to ensure alignment in
              national policies and priorities.{' '}
              <a
                className='text-amber-300 font-bold no-underline'
                href='https://philsa.gov.ph/about/'
                target='_blank'
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
