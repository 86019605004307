import Axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const getPublicSpaceObjects = createAsyncThunk(
  'landing/getPublicSpaceObjects',
  async (res) => {
    try {
      const response = await Axios({
        url: `${process.env.REACT_APP_API}/public/space_objects/`,
        method: 'get',
        data: res,
      }).then((response) => {
        return response;
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const LandingSlice = createSlice({
  name: 'landing',
  initialState: {
    public_space_objects: [],
  },
  reducers: {},
  extraReducers: {
    [getPublicSpaceObjects.fulfilled]: (state, action) => {
      state.public_space_objects = get(action, 'payload');
      state.loading = false;
    },
    [getPublicSpaceObjects.pending]: (state, action) => {
      state.pending = action.error;
      state.loading = true;
    },
    [getPublicSpaceObjects.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const landingState = (state) => state.landing;

export default LandingSlice.reducer;
