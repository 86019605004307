import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import { Grid, TextInput, Text, NumberInput, Textarea } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import moment from 'moment';

export default function EditSpaceObjectModal() {
  const [date, setDate] = useState(null);

  const form = useForm({
    initialValues: {
      name: '',
      image: '',
      cospar_international_designator: '',
      date_launch: null,
      location_launch: '',
      other_launching_state: '',
      nodal_period: '',
      inclination: '',
      apogee: '',
      perigee: '',
      general_function: '',
      geostationary_position: '',
      website: '',
      launch_vehicle: '',
      celestial_body_orbiting: '',
      operator: '',
      other_information: '',
      national_designator: '',
    },

    validate: () => {},
  });
  return (
    <div>
      <form>
        <Grid>
          <Text className='font-bold text-center text-lg mt-4'>
            Information Provided in conformity with the Registration Convention
            or General Assembly resolution 1721 B (XVI)
          </Text>
          <Grid.Col span={12}>
            <Text className='mt-4 italic'>
              Launching State/States/international intergovernmental
              organization
            </Text>
          </Grid.Col>

          <Grid.Col md={6} sm={12}>
            <TextInput
              label='Launching State'
              placeholder='Launching State'
              value={'Philippines'}
              disabled
            />
          </Grid.Col>
          <Grid.Col md={6} sm={12}>
            <TextInput
              label='Other Launching State'
              placeholder='Other Launching state'
              {...form.getInputProps('other_launching_state')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Text className='mt-4 italic'>Designator</Text>
          </Grid.Col>
          <Grid.Col md={6} sm={12}>
            <TextInput
              required
              label='Name'
              placeholder='Space object name'
              {...form.getInputProps('name')}
            />
          </Grid.Col>
          <Grid.Col md={6} sm={12}>
            <TextInput
              required
              label='COSPAR international designator'
              placeholder='COSPAR designator'
              {...form.getInputProps('cospar_international_designator')}
            />
          </Grid.Col>
          <Grid.Col md={6} sm={12}>
            <TextInput
              required
              label='National designator/registration number'
              placeholder='National designator/registration number as used by State of registry'
              {...form.getInputProps('national_designator')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Text className='mt-4 italic'>
              Date and territory or location of launch
            </Text>
          </Grid.Col>
          <Grid.Col md={6} sm={12}>
            <DatePickerInput
              clearable
              label='Date of Launch'
              value={date}
              onChange={(date) => {
                setDate(date);
                form.setValues({
                  date_launch: moment(date).format('YYYY-MM-DD'),
                });
              }}
            />
          </Grid.Col>
          <Grid.Col md={6} sm={12}>
            <TextInput
              required
              placeholder='International Space Station'
              label='Territory or location of launch'
              {...form.getInputProps('location_launch')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Text className='mt-4 italic'>Basic orbital parameters</Text>
          </Grid.Col>
          <Grid.Col md={3} sm={6}>
            <NumberInput
              required
              placeholder='Nodal period'
              label='Nodal Period'
              {...form.getInputProps('nodal_period')}
            />
          </Grid.Col>
          <Grid.Col md={3} sm={6}>
            <NumberInput
              required
              placeholder='Inclination'
              label='Inclination'
              {...form.getInputProps('inclination')}
            />
          </Grid.Col>
          <Grid.Col md={3} sm={6}>
            <NumberInput
              required
              placeholder='Apogee'
              label='Apogee'
              {...form.getInputProps('apogee')}
            />
          </Grid.Col>
          <Grid.Col md={3} sm={6}>
            <NumberInput
              required
              placeholder='Perigee'
              label='Perigree'
              {...form.getInputProps('perigee')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Text className='mt-4 italic'>General Function</Text>
          </Grid.Col>
          <Grid.Col span={12}>
            <Textarea
              required
              minRows={10}
              multiple
              placeholder='General Function'
              label='General Function'
              {...form.getInputProps('general_function')}
            />
          </Grid.Col>
        </Grid>
      </form>
    </div>
  );
}
