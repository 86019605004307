import React, { useEffect } from 'react';
import { ActionIcon, Button, Table } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSpaceObjects, objectState } from 'features/ObjectSlice';
import { get } from 'lodash';
import { IconEye } from '@tabler/icons-react';
import moment from 'moment';

export default function HomeScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { space_objects } = useSelector(objectState);

  useEffect(() => {
    dispatch(getSpaceObjects());
  }, []);

  return (
    <div className='w-full'>
      <div className='flex justify-between lg:flex-row flex-col '>
        <div className='font-poppins text-2xl font-bold'>
          Space Object Dashboard
        </div>
        <Button
          onClick={() => {
            navigate('/space_object/register');
          }}
        >
          Register New Space Object
        </Button>
      </div>

      <div className='mt-10 mx-auto w-[80%]'>
        <Table>
          <thead>
            <tr>
              <th>Space Object Name</th>
              <th>Date Created</th>
              <th>Status</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {get(space_objects, 'results', []).map((object) => (
              <tr>
                <td>{object.name}</td>
                <td>
                  {moment(get(object, 'registry.date_modified')).format(
                    'DD MMMM YYYY'
                  )}
                </td>
                <td>{get(object, 'registry.registration_status')}</td>
                <td>
                  <ActionIcon
                    onClick={() => {
                      navigate(`/space_object/${object.id}`);
                    }}
                  >
                    <IconEye />
                  </ActionIcon>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
