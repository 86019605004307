import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LandingNav from 'components/LandingNav';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from '@mantine/core';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { login, authState } from 'features/AuthSlice';

export default function LoginScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector(authState);
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
  });
  useEffect(() => {
    if (localStorage.getItem('auth')) {
      navigate('/home');
    }
  }, []);

  const handleSubmit = (values) => {
    dispatch(login(values)).then((res) => {
      if (get(res, 'payload.access_token')) {
        showNotification({
          message: 'Login Successful!',
          color: 'green',
        });
        navigate('/home');
      } else {
        showNotification({
          message: get(res, 'payload.error', 'Something went wrong!'),
          color: 'red',
        });
      }
    });
  };
  return (
    <div>
      <LandingNav />
      <form
        onSubmit={form.onSubmit((values) => {
          // console.log(values);
          handleSubmit(values);
        })}
      >
        <Container size={420} my={40}>
          <Title
            align='center'
            sx={(theme) => ({
              fontFamily: `Poppins`,
              fontWeight: 900,
            })}
          >
            Welcome to Philippine Registry of Space Objects!
          </Title>
          <Text color='dimmed' size='sm' align='center' mt={5}>
            Do not have an account yet?{' '}
            <Link to='/register' size='sm' component='button'>
              Create account
            </Link>
          </Text>

          <Paper withBorder shadow='md' p={30} mt={30} radius='md'>
            <TextInput
              label='Email'
              placeholder='you@mantine.dev'
              required
              {...form.getInputProps('email')}
            />
            <PasswordInput
              label='Password'
              placeholder='Your password'
              required
              mt='md'
              {...form.getInputProps('password')}
            />
            <Group position='apart' mt='lg'>
              <Anchor component='button' size='sm'>
                Forgot password?
              </Anchor>
            </Group>
            <Button loading={loading} type='submit' fullWidth mt='xl'>
              Sign in
            </Button>
          </Paper>
        </Container>
      </form>
    </div>
  );
}
