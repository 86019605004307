import React, { useState } from 'react';
import {
  TextInput,
  Textarea,
  Text,
  Grid,
  Stepper,
  Button,
  Group,
  FileInput,
  NumberInput,
} from '@mantine/core';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import { onRegisterObject, objectState } from 'features/ObjectSlice';
import RegistrationSummary from 'components/RegistrationSummary';
import { IconArrowNarrowLeft } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import validateForm from 'components/common/validateForm';
import moment from 'moment';

export default function RegisterSpaceObjectScreen() {
  const [active, setActive] = useState(0);
  const [date, setDate] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector(objectState);
  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 4 ? current + 1 : current;
    });

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));
  const form = useForm({
    initialValues: {
      name: '',
      image: '',
      cospar_international_designator: '',
      date_launch: null,
      location_launch: '',
      other_launching_state: '',
      nodal_period: '',
      inclination: '',
      apogee: '',
      perigee: '',
      general_function: '',
      geostationary_position: '',
      website: '',
      launch_vehicle: '',
      celestial_body_orbiting: '',
      operator: '',
      other_information: '',
      national_designator: '',
    },

    validate: (values) => validateForm(values, active),
  });

  const handleSubmit = () => {
    console.log(form.values);
    const values = form.values;
    const formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });
    dispatch(onRegisterObject(formData)).then((res) => {
      console.log(res);
      if (get(res, 'payload')) {
        showNotification({
          message: get(res, 'payload.info.message', 'Success'),
          color: 'green',
        });
        navigate('/home');
      } else {
        showNotification({
          message: get(res, 'error.message', 'Something went wrong'),
          color: 'red',
        });
      }
    });
  };

  return (
    <div className='w-full'>
      <div className='font-poppins text-2xl font-bold'>
        New Registration of Space Object
      </div>
      <div className='flex justify-end'>
        <Button
          onClick={() => window.history.back()}
          leftIcon={<IconArrowNarrowLeft />}
          variant='white'
        >
          Back to Dash
        </Button>
      </div>
      <div className='w-[85%] mx-auto'>
        <form
          className='my-10'
          onSubmit={form.onSubmit((values) => {
            console.log(values);
          })}
        >
          <Stepper
            active={active}
            onStepClick={setActive}
            breakpoint='sm'
            allowNextStepsSelect={false}
          >
            <Stepper.Step
              label='Part 1'
              description='Space Object Registration Requirements'
            >
              <Grid>
                <Text className='font-bold text-center text-lg mt-4 w-full'>
                  Document Requirements for the Operator
                </Text>
                <Grid.Col span={12}>
                  <Text className='mt-4 italic'>
                    Launching State/States/international intergovernmental
                    organization
                  </Text>
                </Grid.Col>
              </Grid>
            </Stepper.Step>

            <Stepper.Step label='Part 2' description='Space Object Information'>
              <Grid>
                <Text className='font-bold text-center text-lg mt-4'>
                  Information Provided in conformity with the Registration
                  Convention or General Assembly resolution 1721 B (XVI)
                </Text>
                <Grid.Col span={12}>
                  <Text className='mt-4 italic'>
                    Launching State/States/international intergovernmental
                    organization
                  </Text>
                </Grid.Col>

                <Grid.Col md={6} sm={12}>
                  <TextInput
                    label='Launching State'
                    placeholder='Launching State'
                    value={'Philippines'}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col md={6} sm={12}>
                  <TextInput
                    label='Other Launching State'
                    placeholder='Other Launching state'
                    {...form.getInputProps('other_launching_state')}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text className='mt-4 italic'>Designator</Text>
                </Grid.Col>
                <Grid.Col md={6} sm={12}>
                  <TextInput
                    required
                    label='Name'
                    placeholder='Space object name'
                    {...form.getInputProps('name')}
                  />
                </Grid.Col>
                <Grid.Col md={6} sm={12}>
                  <TextInput
                    required
                    label='COSPAR international designator'
                    placeholder='COSPAR designator'
                    {...form.getInputProps('cospar_international_designator')}
                  />
                </Grid.Col>
                <Grid.Col md={6} sm={12}>
                  <TextInput
                    required
                    label='National designator/registration number'
                    placeholder='National designator/registration number as used by State of registry'
                    {...form.getInputProps('national_designator')}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text className='mt-4 italic'>
                    Date and territory or location of launch
                  </Text>
                </Grid.Col>
                <Grid.Col md={6} sm={12}>
                  <DatePickerInput
                    clearable
                    label='Date of Launch'
                    value={date}
                    onChange={(date) => {
                      setDate(date);
                      form.setValues({
                        date_launch: moment(date).format('YYYY-MM-DD'),
                      });
                    }}
                  />
                </Grid.Col>
                <Grid.Col md={6} sm={12}>
                  <TextInput
                    required
                    placeholder='International Space Station'
                    label='Territory or location of launch'
                    {...form.getInputProps('location_launch')}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text className='mt-4 italic'>Basic orbital parameters</Text>
                </Grid.Col>
                <Grid.Col md={3} sm={6}>
                  <NumberInput
                    required
                    placeholder='Nodal period'
                    label='Nodal Period'
                    {...form.getInputProps('nodal_period')}
                  />
                </Grid.Col>
                <Grid.Col md={3} sm={6}>
                  <NumberInput
                    required
                    placeholder='Inclination'
                    label='Inclination'
                    {...form.getInputProps('inclination')}
                  />
                </Grid.Col>
                <Grid.Col md={3} sm={6}>
                  <NumberInput
                    required
                    placeholder='Apogee'
                    label='Apogee'
                    {...form.getInputProps('apogee')}
                  />
                </Grid.Col>
                <Grid.Col md={3} sm={6}>
                  <NumberInput
                    required
                    placeholder='Perigee'
                    label='Perigree'
                    {...form.getInputProps('perigee')}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text className='mt-4 italic'>General Function</Text>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Textarea
                    required
                    minRows={10}
                    multiple
                    placeholder='General Function'
                    label='General Function'
                    {...form.getInputProps('general_function')}
                  />
                </Grid.Col>
              </Grid>
            </Stepper.Step>
            <Stepper.Step label='Part 3' description='Additional Information'>
              <Grid>
                <Text className='font-bold text-center text-lg mt-4'>
                  Additional information for use in the United Nations Register
                  of Objects Launched into Outer Space, as recommended in
                  General Assembly resolution 62/101
                </Text>

                <Grid.Col span={12}>
                  <TextInput
                    required
                    placeholder='degrees east'
                    label='Geostationary Position'
                    {...form.getInputProps('geostationary_position')}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    required
                    placeholder='Website URL'
                    label='Website'
                    {...form.getInputProps('website')}
                  />
                </Grid.Col>
              </Grid>
            </Stepper.Step>
            <Stepper.Step
              label='Part 4'
              description='Additional Voluntary Information'
            >
              <Grid>
                <Grid.Col span={12}>
                  <Text className='mt-4 italic'>Basic information</Text>
                </Grid.Col>

                <Grid.Col md={6} sm={12}>
                  <TextInput
                    required
                    placeholder='Launch Vehicle'
                    label='Launch Vehicle'
                    {...form.getInputProps('launch_vehicle')}
                  />
                </Grid.Col>
                <Grid.Col md={6} sm={12}>
                  <TextInput
                    required
                    placeholder='Earth...'
                    label='Celestial body space object is Orbiting'
                    {...form.getInputProps('celestial_body_orbiting')}
                  />
                </Grid.Col>
                <Grid.Col md={6} sm={12}>
                  <TextInput
                    required
                    placeholder='Other information...'
                    label='Other Information'
                    {...form.getInputProps('other_information')}
                  />
                </Grid.Col>
                <Grid.Col md={6} sm={12}>
                  <FileInput
                    withAsterisk
                    label='Upload Space Object Image'
                    placeholder='Upload image'
                    accept='image/png, image/gif, image/jpeg'
                    {...form.getInputProps('image')}
                  />
                  <span className='small'>
                    Please upload jpg, gif, png format only.
                  </span>
                </Grid.Col>
              </Grid>
            </Stepper.Step>
            <Stepper.Completed>
              <RegistrationSummary form={form} />
            </Stepper.Completed>
          </Stepper>

          <Group position='center' mt='xl'>
            <Button disabled={loading} variant='default' onClick={prevStep}>
              Back
            </Button>
            {active === 4 ? (
              <Button onClick={handleSubmit} loading={loading}>
                Submit
              </Button>
            ) : (
              <Button onClick={nextStep}>Next step</Button>
            )}
          </Group>
        </form>
      </div>
    </div>
  );
}
