import React, { useEffect } from 'react';
import { Button, LoadingOverlay, Modal } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { viewSpaceObject, objectState } from 'features/ObjectSlice';
import { get } from 'lodash';
import TableSummary from 'components/TableSummary';
import EditSpaceObjectModal from 'components/EditSpaceObjectModal';
export default function SpaceObjectViewScreen() {
  const { loading, space_object } = useSelector(objectState);
  const params = useParams();
  const dispatch = useDispatch();

  const [editObjectOpened, handleEditObject] = useDisclosure(false);

  useEffect(() => {
    dispatch(viewSpaceObject({ id: params.id }));
  }, []);

  return (
    <>
      <Modal
        size={'70%'}
        opened={editObjectOpened}
        onClose={() => {
          handleEditObject.close();
        }}
        title={<div className='font-poppins font-bold'>Edit Space Object</div>}
      >
        <EditSpaceObjectModal />
      </Modal>
      <LoadingOverlay
        visible={loading}
        overlayBlur={10}
        loaderProps={{ size: 'lg', color: 'darkblue' }}
      />
      <div className='w-full'>
        <div className='flex justify-between md:flex-row flex-col '>
          <div className='font-poppins text-2xl font-bold'>
            {get(space_object, 'name')}
          </div>
          <Button
            onClick={() => {
              handleEditObject.open();
            }}
          >
            Edit Submission
          </Button>
        </div>

        <div className='mt-10 grid grid-cols-1 md:grid-cols-12 w-full gap-5'>
          <TableSummary data={space_object} />
          <div className='col-span-6'>
            <img
              className='object-contain w-full'
              src={get(space_object, 'image')}
            />
            <div>
              <Button onClick={() => {}} fullWidth className='mt-2'>
                Change Status Types
              </Button>
              <Button fullWidth className='mt-2'>
                Change Status Operations
              </Button>
              <Button fullWidth className='mt-2'>
                Change Supervision
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
