import Axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const onRegister = createAsyncThunk(
  'registration/onRegister',
  async (res) => {
    try {
      const response = await Axios({
        url: `${process.env.REACT_APP_API}/register/`,
        method: 'post',
        data: res,
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const RegisterSlice = createSlice({
  name: 'register',
  initialState: {
    formValues: {},
    loading: false,
    registerData: '',
  },
  reducers: {
    setFormValues: (state, payload) => {
      state.formValues = payload;
    },
  },
  extraReducers: {
    [onRegister.fulfilled]: (state, action) => {
      // Add user to the state array
      state.registerData = get(action, 'payload');
      state.loading = false;
    },
    [onRegister.pending]: (state, action) => {
      // Add user to the state array
      state.pending = action.error;
      state.loading = true;
    },
    [onRegister.rejected]: (state, action) => {
      // Add user to the state array
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const { setFormValues } = RegisterSlice.actions;

export const registerState = (state) => state.register;

export default RegisterSlice.reducer;
