const validateForm = (values, active) => {
  // const checkNumber = /^\d*(\.\d+)?$/;

  if (active === 1) {
    return {
      name: values.name.trim().length === 0 ? 'This is a required field' : null,
      cospar_international_designator:
        values.cospar_international_designator.trim().length === 0
          ? 'This is a required field'
          : null,
      national_designator:
        values.national_designator.trim().length === 0
          ? 'This is a required field'
          : null,
      location_launch:
        values.location_launch.trim().length === 0
          ? 'This is a required field'
          : null,
      nodal_period:
        values.nodal_period.length === 0 ? 'This is a required field' : null,
      inclination:
        values.inclination.length === 0 ? 'This is a required field' : null,
      apogee: values.apogee.length === 0 ? 'This is a required field' : null,
      perigee: values.perigee.length === 0 ? 'This is a required field' : null,
      general_function:
        values.general_function.trim().length === 0
          ? 'This is a required field'
          : null,
    };
  } else if (active === 2) {
    return {
      geostationary_position:
        values.geostationary_position.trim().length === 0
          ? 'This is a required field'
          : null,
      website:
        values.website.trim().length === 0 ? 'This is a required field' : null,
    };
  } else if (active === 3) {
    return {
      launch_vehicle:
        values.launch_vehicle.trim().length === 0
          ? 'This is a required field'
          : null,
      celestial_body_orbiting:
        values.celestial_body_orbiting.trim().length === 0
          ? 'This is a required field'
          : null,

      other_information:
        values.other_information.trim().length === 0
          ? 'This is a required field'
          : null,
    };
  }
};

export default validateForm;
