import React from 'react';
import { Outlet } from 'react-router-dom';
import SideNav from 'components/SideNav';
import MobileNav from 'components/MobileNav';
import { useViewportSize } from '@mantine/hooks';

export default function AuthScreen() {
  const { width } = useViewportSize();
  return (
    <div>
      <div className='lg:flex md:block'>
        {width < 992 ? <MobileNav /> : <SideNav />}
        <div className='mx-auto my-5 lg:ml-80 lg:w-[75%] w-[92%]'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
